import React from 'react';
import Apiservice from '../service/apiService';
import Toastr from 'toastr';
import Loader from 'react-loader';
import DownloadFilesList from './DownloadFilesList';


class DownloadFile extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
          fileName     : '',
          password     : '',
          loading      : false,
          fileList     : [],
          showFileList : false,
          email        : ''
        };

        this.getFile             = this.getFile.bind(this);
        this.handleChange        = this.handleChange.bind(this);
        this.convertBase64toBlob = this.convertBase64toBlob.bind(this);
        this.initialize();
    }

    onSuccess() {
        this.setState({ loading: false});
    }

      
    initialize() {
        this.api = Apiservice.getInstance();
    }

    handleChange(event) {    
            let stateKey = (event.target.name).toLowerCase();
            this.setState({[stateKey]: event.target.value});
    }

    convertBase64toBlob = (base64Data, contentType) => {
        contentType = contentType || '';
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays  = new Array(slicesCount);
    
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
          const begin = sliceIndex * sliceSize;
          const end = Math.min(begin + sliceSize, bytesLength);
    
          const bytes = new Array(end - begin);
          for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
          }
          byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
      }
    
      getFile(){
        var param  = this.props.match.params;
        var details = {
            JobNumber : param.jobNumber,
            Password  : this.state.password
        }; 
        this.setState({loading: true});
        this.api.getFileListForUser(details)            
        .then(res => {
            if(res && res.data){
                const data = res.data;
                if(!data.ErrorStatus){
                    this.setState({fileList: data.File, showFileList: true, email: data.Email});
                }
                else{
                    Toastr.error(data.ErrorStatus);
                }                
            }
            this.onSuccess();
        }).catch (error => {
            this.onSuccess();
            Toastr.error(error);
            throw (error);
        }); 
    }

    render(){
        const password = <div className='col-xs-4 col-sm-4 center'>
                            <label for='password'>Enter the Password</label>
                            <input type='password' name='Password' id='password' className='form-control' value={this.state.password} onChange={this.handleChange} />
                         </div>
        var showFileList = this.state.showFileList;
        return (
            <div className='dashboard-container'>
                {!showFileList && <div>
                    <Loader loaded={!this.state.loading} />
                    <div className='dashboard-content'>
                        <div className='row'>
                            {password}
                            <div className='col-xs-2 col-sm-2 button-center'>
                                <input type='button' value='Get File' onClick={this.getFile} disabled={!this.state.password || this.state.loading} className="btn btn-default " />
                            </div>
                        </div>
                    </div>
                </div>}
                {showFileList && 
                    <DownloadFilesList email={this.state.email} fileList={this.state.fileList} />}
            </div>
        );
    }
}

export default DownloadFile