import React from 'react';
import './MultipleStatus.css';
import Toastr from 'toastr';

import apiservice from '../../service/apiService';

class MultipleStatus extends React.Component {

    constructor(props) {
        super(props);
        let queryparams = this.props.match.params;
        queryparams = queryparams.multipleRequestId;
        this.initialize();
        this.state = {}
        this.getStatus(queryparams);
    }

    initialize() {
        this.api = apiservice.getInstance();
    }

    getStatus(statusFromServer) {
        this.api.getMultipleStatus(statusFromServer)
            .then(res => {
                this.buildDisplayData(res.data);
                this.setState({});
            })
            .catch(err => {
                console.log(err);
                Toastr.error(err);
                throw (err);
            });
    }

    buildDisplayData(res) {
        // res = "[{\"JobNumber\":\"13734c12-ad89-4d9e-95e5-f44ded2411f3\",\"STATUS\":\"Request Initialized\",\"CreatedTime\":\"Jan 16 2019  6:35:14\",\"Description\":\"\"},{\"JobNumber\":\"13734c12-ad89-4d9e-95e5-f44ded2411f3\",\"STATUS\":\"Service Or Batch Created\",\"CreatedTime\":\"Jan 16 2019  6:35:15\",\"Description\":\"\"},{\"JobNumber\":\"4da9e165-2ffd-4284-adf1-d99d43a1f868\",\"STATUS\":\"Request Initialized\",\"CreatedTime\":\"Jan 16 2019  6:35:16\",\"Description\":\"\"},{\"JobNumber\":\"4da9e165-2ffd-4284-adf1-d99d43a1f868\",\"STATUS\":\"Service Or Batch Created\",\"CreatedTime\":\"Jan 16 2019  6:35:17\",\"Description\":\"\"},{\"JobNumber\":\"80189444-b294-4eab-b596-33993b2cdb58\",\"STATUS\":\"Request Initialized\",\"CreatedTime\":\"Jan 16 2019  6:35:15\",\"Description\":\"\"},{\"JobNumber\":\"80189444-b294-4eab-b596-33993b2cdb58\",\"STATUS\":\"Service Or Batch Created\",\"CreatedTime\":\"Jan 16 2019  6:35:16\",\"Description\":\"\"}]";
        let response = this.restructureData(res);
        let stateObj = {};
        let row = 1;
        this.displayData = "<table class=\"table table-striped\"><th>Job</th><th>Current Status</th><th>Check</th>";
        for (let key in response) {
            if (response.hasOwnProperty(key) && Array.isArray(response[key])) {
                let rowLength = response[key].length - 1;
                let singleStatus = './#/status/' + key;
                let drowData = "<tr align=\"center\"><td>Job " +
                    row + " (" +
                    response[key][rowLength].SerialOrBatchNumber + ")</td><td>" +
                    response[key][rowLength].STATUS + "</td><td><li class=\"btn btn-success\"><a href=\"" +
                    singleStatus + "\">Check Job Status</a></li></td></tr>";
                this.displayData = this.displayData + drowData;
                row++;
            }
        }
        if (document.getElementById("displayDataMultiple")) {
            document.getElementById("displayDataMultiple").innerHTML = this.displayData;
        }
        this.setState(stateObj);
    }

    restructureData(res) {
        res = JSON.parse(res);
        let result = {};
        for (let i = 0; i < res.length; i++) {

            let jobKey = res[i].JobNumber;
            if (result[jobKey]) {
                result[jobKey].push(res[i]);
            } else {
                result[jobKey] = [];
                result[jobKey][0] = res[i];
            }
        }
        return result;
    }

    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <h3 id="multiStatusHeading">
                            Current Status of Multiple Job:
                        </h3>
                    </div>
                    <div id="displayDataMultiple"></div>
                </div>
            </div>
        );
    }


}

export default MultipleStatus;