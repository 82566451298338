import React from 'react';
import './status.css';
import Toastr from 'toastr';
import apiservice from '../../service/apiService';
import Loader from 'react-loader';
import moment from 'moment';

class Status extends React.Component {

    displayData;
    serverInfo = {
        'Request Initialized': 'step1',
        'step1 unknown error': 'step1Err',
        'Service Or Batch Created': 'step2',
        'step2 unknown error': 'step2Err',
        'DataBook Genarated': 'step3',
        'Errer In genarating Databook': 'step3Err',

    };
    clientInfo = {
        'step1': 'Request Initialized',
        'step1Err': 'Error initializing request',
        'step2': 'Service Or Batch Created',
        'step2Err': 'Error creating service or batch',
        'step3': 'Databook Generation',
        'step3Err': 'Error in generating Databook'
    };
    api;
    constructor(props) {
        super(props);
        // console.log(this.props);
        // console.log(this.props.location.search);
        let queryparams = this.props.match.params.jobNumber;
        // console.log(this.props.match.params.jobId);
        // console.log(props);
        this.state = {
            'step1': false,
            'step1Err': false,
            'step2': false,
            'step2Err': false,
            'step3': false,
            'step3Err': false,
            'jobNumber':'',
            loading :false
        }
        this.initialize();
        this.jobId = queryparams;
        // console.log(queryparams);
        this.getStatus(queryparams);
    }

    initialize() {
        this.api = apiservice.getInstance();
        this.displayData = '';
    }

    reload() {
        var ua = window.navigator.userAgent;
      var msie = ua.indexOf('MSIE ');
        var trident = ua.indexOf('Trident/');
        if (msie > 0 || trident > 0) {
            window.location.reload();
        }
        else {
            this.getStatus(this.jobId);
        }
    }

    buildDisplayData(res) {
        let response = JSON.parse(res);
        let stateObj = {};
        // console.log(response);
        //This will not rendered inside react hence keyword class is used instead of classname
        this.displayData = "<table class=\"table table-striped\"><th>Created Date</th><th>Status</th><th>Description</th>";
        for (let i = 0; i < response.length; i++) {
            let drowData = "<tr align=\"center\"><td>" + moment(new Date(response[i].CreatedTime)).format('MMMM DD YYYY h:mm A z')  + " UTC </td><td>" + this.clientInfo[this.serverInfo[response[i].STATUS]] + "</td><td>" + response[i].Description + "</td></tr>";
            this.displayData = this.displayData + drowData;
            stateObj['jobNumber'] = '(' + response[i].SerialOrbatch + ')';
            if (this.serverInfo[response[i].STATUS]) {
                let info = this.serverInfo[response[i].STATUS];
                stateObj[info] = true;
               
            }
        }
        this.displayData = this.displayData + "</table>";

        // this.displayData = this.generateHTML(this.displayData);
        document.getElementById("status").innerHTML = this.displayData;
        this.setState(stateObj);
    }

    generateHTML(data) {
        let Disdata = (
            <div>
                {data}
            </div>);
        return Disdata;
    }

    getStatus(statusFromServer) {
        this.setState({loading: true});
        this.api.getStatus(statusFromServer)
            .then(res => {
                this.buildDisplayData(res.data);
                this.setState({loading: false});
            })
            .catch(err => {
                this.setState({loading: false});
                console.log(err);
                Toastr.error(err);
                throw (err);
            });
    }

    steps(val) {
        let stepName = 'step' + val;
        let stepErr = stepName + 'Err';


        return (
            <div className="col-md-3 col-center-cont stepClass">
                <div className={this.state[stepName] ? 'greenBg' : this.state[stepErr] ? 'redBg' : 'yellowBg'}><p id="stepHead">Step</p></div>
                <input type="button" value={val} className={this.state[stepName] ? 'btn roundButton greenBg' : this.state[stepErr] ? 'btn roundButton redBg' : 'btn roundButton yellowBg'} />
                <div><p>{this.clientInfo[stepName]}</p></div>
                <div className={this.state[stepName]}><h4>{this.state[stepName] ? 'Completed' : this.state[stepErr] ? 'Failed' : 'In-progress'}</h4></div>
            </div>
        );
    }

    render() {
        return (
            <div className="container">
                <Loader loaded={!this.state.loading} />
                <div className="row">
                    <div className="col-md-10">
                        <h3 id="singleStatusHeading">
                            Current Status of Job {this.state.jobNumber}:
                        </h3>
                    </div>
                    <div className="col-md-2">
                        <input type="button" className="btn btn-success" value="Refresh" onClick={() => this.reload()} />

                    </div>
                </div>
                <div className="row">
                    {this.steps(1)}
                    {this.steps(2)}
                    {this.steps(3)}
                </div>
                <div id="status">
                </div>
            </div>
        );
    }
}

export default Status;