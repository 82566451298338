//THIS FILE CONTAIN SERVER LINKED CONFIGURATION.
//CHANGE THIS FILE NEED TO CHECK BOTH CLIENT AND SERVER SIDE

import axios from 'axios';
import environment from '../environment';
import interpolate from'./interpolate';

class apiService {
    // eslint-disable-next-line no-useless-constructor
    constructor() {
        this.server = environment.server;
        this.url = 'http{ssl}://{domain}{port}/{vdir}{api}/'.interpolate(this.server);        
    }

    static instance = null;
    static createInstance() {
        var object = new apiService();
        return object;
    }

    static getInstance() {
        if (!apiService.instance) {
            apiService.instance = apiService.createInstance();
        }
        return apiService.instance;
    }

    // partNumberreqByDelNumber() {
    //     axios.get(`http://kbg1mip0.kongsberg.fmcweb.com:8000/sap/opu/odata/sap/ZDBK_SEAL_SRV/SerialNumberAssyResponseSet?$format=json&$filter=DeliveryNum eq '82242585'`)
    //         .then(res => {
    //             console.log(res);
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         })
    // }

    // reqByDelNumber(event) {
    //     this.handleChange(event);
    //     let headers = {
    //         'Content-Type': 'application/json',
    //         'Access-Control-Allow-Origin': 'http://localhost:3000'
    //     }
    //     //api.partNumberreqByDelNumber();
    //     axios.get(`http://kbg1mip0.kongsberg.fmcweb.com:8000/sap/opu/odata/sap/ZDBK_SEAL_SRV/SerialNumberAssyResponseSet?$format=json&$filter=DeliveryNum eq '82242585'`, headers)
    //         .then(res => {
    //             console.log(res);
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         });

    // CREATE JOB USING SERIAL NUMBER
    getBySerNumber(data) {
        let url = this.url + 'databook/createService';
        return axios.post(url, data);
    }

    // CREATE JOB USING BATCH NUMBER
    getByBatchNumber(data) {
        let url = this.url + 'databook/createBatch';
        return axios.post(url, data);
    }

    // RETURN STATUS OF THE JOB
    getStatus(JobNumber) {
        let url = this.url + 'databook/getStatusHistoryByJobNumber';
        return axios.get(url,{params:{JobNumber: JobNumber}});
    }

    // RETURN STATUS OF MULTIPLE REQUEST JOB
    getMultipleStatus(JobNumber) {
        let url = this.url + 'databook/getStatusHistoryByMultiJobIdNumber?JobNumber=' + JobNumber;
        return axios.get(url);
    }

    

    // GET THE LIST OF DATA USING DELIVERY, CUSTOMER PO NUMER, PURCHASE ORDER NUMBER
    getSerialList(option, fieldvalue) {
        let url = this.url +'databook/getDataBy';
        if (option === 'CustPoNum') {
            url = url + 'CustPoNumber?' + option + '=' + fieldvalue;
        } else {
            url = url + option + '?' + option + '=' + fieldvalue;
        }

        return axios.get(url);
    }

    postMultipleSerialBatch(data){

        let url = this.url + 'databook/CreateMultipleRequest';
        return axios.post(url, data);
        // let dummy = {'success':''};
        // return dummy;
    }

    getDatabookFileWithPassword(data) {
        let url = this.url + 'databook/getDatabookFileWithPassword';
        return axios.post(url, data);
    }

    getFileListForUser(data) {
        let url = this.url + 'databook/getFileListForUser';
        return axios.post(url, data);
    }    
}

export default apiService;