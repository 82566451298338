const environment = {};

const  location             = window.location;
environment.isLocalhost             = (location.hostname === 'localhost');
environment.isDevelopment           = ((location.href.indexOf('in001vs0034') >= 0));
environment.isTest                  = ((location.href.indexOf('databook.lab') >= 0));
environment.isProduction            = ((location.href.indexOf('databook.apps') >= 0));
environment.isDevMachine            = ((location.href.indexOf('in001wl2973') >= 0));
environment.profiling               = (environment.isLocalhost && false);

environment.server =
  (() => {
    if (environment.isLocalhost) {
      return {
        ssl     : '',
        domain  : 'localhost',
        port    : ':4002',
        vdir    : '',
        cors    : true,
        api     : 'api'
      };
    }

    if (environment.isDevMachine) {
      return {
        ssl     : (location.protocol === 'https:') ? 's' : '',
        domain  : location.hostname,
        port    : (location.protocol === 'https:') ? ':45456': ':45455',
        vdir    : '',
        cors    : true,
        api     : 'api'
      };
    }

    var pathname = location.pathname.split('/');
    var vdir     = '';

    for (var i = 0; i < pathname.length - 1; i++) {
      vdir += pathname[i];
    }

    if (vdir !== '') {
      vdir += '/';
    }

    return {
      ssl     : (location.protocol === 'https:') ? 's' : '',
      domain  : location.hostname,
      port    : (location.port === '') ? '' : ':' + parseInt(location.port, 10),
      vdir    : vdir,
      cors    : false,
      api     : 'api'
    };
  })();

export default environment;

// export const API_BASE_URL = 'http://in001vs0034:3002/api/databook/';
// export const API_CLIENT_URL = 'http://in001vs0034:6512/';

// export const API_BASE_URL = 'http://localhost:4002/api/databook/';
// export const API_CLIENT_URL = 'http://localhost:3000/';