import React from 'react';
import {Redirect} from "react-router-dom";

class LinkRedirect extends React.Component{
    render() {
        var routeName = this.props.routeName;
        return(
           <Redirect to={'/' + routeName}/>
        );
    }
}

export default LinkRedirect;