var makeInterpolator = (function() {
  var rc = {
    '\n': '\\n', '\"': '\\\"'
  };

  return function makeInterpolator(str) {
    var s = 'return "' + (
        str
        .replace(/["\n\r]/g, function($0) {
          return rc[$0];
        })
        .replace(/\{([\s\S]+?)\}/g, '" + o.$1 + "')
      ) + '";';
    // console.log(s);
    var f = new Function('o', s);
    return f;
  };
}());

        // .replace(/\{([\s\S]+?)\}/g, '" + String.prototype.interpolate.get(o, "$1") + "')

String.prototype.interpolate = function(obj) {
  var cache = String.prototype.interpolate.__cache;
  if (!cache)
    cache = String.prototype.interpolate.__cache = {};

  var fn = cache[this];
  if (!fn) {
     fn = makeInterpolator(this);
     cache[this] = fn;
  }

  return fn(obj);
}