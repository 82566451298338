import React from 'react';
import Apiservice from '../service/apiService';
import Toastr from 'toastr';
import Loader from 'react-loader';
import FileNameRow from './FileNameRow';


class DownloadFilesList extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
          fileList: [],
          email : '',
          loading: false,
          selectAll : false
        };

        this.downloadFile        = this.downloadFile.bind(this);
        this.handleChange        = this.handleChange.bind(this);
        this.convertBase64toBlob = this.convertBase64toBlob.bind(this);
        this.setSelectedFiles    = this.setSelectedFiles.bind(this);
        this.handleCheckAll      = this.handleCheckAll.bind(this);
        this.initialize();
    }
    initialize() {
        this.api = Apiservice.getInstance();
    }
    componentDidMount() {
      var files = this.props.fileList;
        var emailId = this.props.email;
        this.setState({fileList : files, email : emailId});

    }
    onSuccess() {
        this.setState({ loading: false});
    }
    handleChange(event) {    
            let stateKey = (event.target.name).toLowerCase();
            this.setState({[stateKey]: event.target.value});
    }
    convertBase64toBlob = (base64Data, contentType) => {
        contentType = contentType || '';
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);
    
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
          const begin = sliceIndex * sliceSize;
          const end = Math.min(begin + sliceSize, bytesLength);
    
          const bytes = new Array(end - begin);
          for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
          }
          byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
      }

    setSelectedFiles(fileName, isSelectChecked){
        let selectAll = this.state.selectAll;
        const files = this.state.fileList.map(file =>{
            if(file.FileName == fileName){
                file.selected = isSelectChecked;
            }
            return file;
        });
        const filesLength = this.state.fileList.length;
        const filesSelectedLength = this.state.fileList.filter(file => file.selected).length;
        if(filesLength != filesSelectedLength && this.state.selectAll){
            selectAll = false;
        }
        else if(filesLength == filesSelectedLength && !this.state.selectAll){
            selectAll = true;
        }
        this.setState({fileList : files, selectAll : selectAll});
    }

    handleCheckAll(event) {
        const isSelectAllChecked = event.target.checked;
        this.setState({selectAll: isSelectAllChecked}); 
        const files = this.state.fileList.map(file => {
            file.selected = isSelectAllChecked;
            return file;
        });
        this.setState({ fileList: files });
    }

    downloadFile() {
        var selectedFile = this.state.fileList.filter(file => file.selected).map(file => file.FileName);
        var details = {
            EmailId: this.state.email,
            FileList: selectedFile
        };
        this.setState({ loading: true });
        this.api.getDatabookFileWithPassword(details)
            .then(res => {
                if (res && res.data) {
                    const data = res.data;
                    if (navigator.userAgent.match(/iPad/i) != null) {
                        data.forEach(list => {
                            if (!list.ErrorStatus) {
                                let file = this.convertBase64toBlob(list.File, 'application/pdf');
                                let fileURL = window.URL.createObjectURL(file);
                                window.open(fileURL);
                            }
                            else {
                                Toastr.error(data.ErrorStatus);
                            }
                        });
                    }
                    else {
                        data.forEach(list => {
                            if (!list.ErrorStatus) {
                                const blob = this.convertBase64toBlob(list.File, 'application/pdf');
                                const fileName = list.FileName;

                                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                                }
                                else {
                                    const fileURL = URL.createObjectURL(blob);
                                    const tempLink = document.createElement('a');
                                    tempLink.href = fileURL;
                                    tempLink.download = fileName;
                                    tempLink.click();
                                    tempLink.remove();
                                }
                            }
                            else {
                                Toastr.error(data.ErrorStatus);
                            }
                        });
                    }

                } else {
                    Toastr.error('File not available');
                }
                this.onSuccess();
            }).catch(error => {
                this.onSuccess();
                Toastr.error(error);
                throw (error);
            });
    }

    render(){
        var files = this.state.fileList.map((file,index) => <FileNameRow key={index} selectFile={this.setSelectedFiles} FileName={file.FileName} CreatedDate={file.CreatedDate} Selected={file.selected}/>);
        var noFilesFound = files.length==0 ? <tr><td colSpan={4}>No files found </td></tr> : null;
        var downloadList = this.state.fileList.filter(file => {
            return file &&file.selected
        });
        console.log('file list',this.state.fileList);
        return (
            <div className='dashboard-container'>
                <Loader loaded={!this.state.loading} />
                <div className='dashboard-content'>
                    <div className='row'>
                        <div className='col-sm-1 col-md-2'></div>
                        <div className='col-sm-10 col-md-8'>
                        <div className='pull-right download-button'>
                            <input type='button' value='Download' onClick={this.downloadFile} disabled={!downloadList.length || this.state.loading} className="btn btn-default " />
                        </div>
                            <table className='table table-striped table-bordered table-hover table-full-width files-table'>
                                <thead>
                                    <tr>
                                        <th className='select-check-box'><input type="checkbox" onChange={this.handleCheckAll} checked={this.state.selectAll} /></th>
                                        <th>File Name</th>
                                        <th>Created Date</th>
                                        <th>Expiry Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {files}
                                    {noFilesFound}
                                </tbody>
                            </table>
                        </div>
                        <div className='col-sm-1 col-md-2'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DownloadFilesList