import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import './partNumber.css';
import { FormErrors } from '../FormErrors/FormErrors';
import apiservice from '../../service/apiService';
import LinkRedirect from '../../component/LinkRedirect.jsx';
import Toastr from 'toastr';
import Loader from 'react-loader';

class PartNumber extends React.Component {
    api;
    BatchSerial = 'Serial #';
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            value: '',
            MaterialNumber: '',
            SerialNumber: '',
            CustomerName: '',
            CustomerOrederNumber: '',
            CustomerMaterialNumber: '',
            SalesOrderNum: '',
            lineItem: '',
            Quantity: '',
            ShippingDate: '',
            RequesterName: '',
            RequesterEmail: '',
            formErrors: {
                MaterialNumber: '',
                SerialNumber: '',
                CustomerName: '',
                RequesterEmail: '',
                RequesterName: ''
            },
            req: {},
            RequesterEmailValid: false,
            RequesterNameValid: false,
            MaterialNumberValid: false,
            SerialNumberValid: false,
            CustomerNameValid: false,
            wasSubmitted: false,
            jobNumber : '',
            redirectPage : false,
            optionSelected: 'Serial Number',
            loading      : false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.optionChanged = this.optionChanged.bind(this);
        this.fillData = this.fillData.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.setLoaderVisibility = this.setLoaderVisibility.bind(this);
        this.initialize();

    }


    initialize() {
        this.api = apiservice.getInstance();
    }

    handleChange(event) {
        let stateChangeObj = {};
        if (event.target.name) {
            let stateKey = event.target.name;
            stateChangeObj[stateKey] = event.target.value;
            this.setState(stateChangeObj, () => {
                this.validateField(stateKey, stateChangeObj[stateKey])
            });
        }
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let RequesterEmailValid = this.state.RequesterEmailValid;
        let RequesterNameValid = this.state.RequesterNameValid;
        let MaterialNumberValid = this.state.MaterialNumberValid;
        let CustomerNameValid = this.state.CustomerNameValid;
        let SerialNumberValid = this.state.SerialNumberValid;

        switch (fieldName) {
            case 'MaterialNumber':
                MaterialNumberValid = value.length >= 2;
                fieldValidationErrors.MaterialNumber = MaterialNumberValid ? '' : ' Please enter the material number';
                break;
            case 'SerialNumber':
                SerialNumberValid = value.length >= 2;
                if (this.state.optionSelected === 'Serial Number') {
                    fieldValidationErrors.SerialNumber = SerialNumberValid ? '' : ' Please enter the serial number';
                } else {
                    fieldValidationErrors.SerialNumber = SerialNumberValid ? '' : ' Please enter the batch number';
                }
                break;
            case 'CustomerName':
                CustomerNameValid = value.length >= 2;
                fieldValidationErrors.CustomerName = CustomerNameValid ? '' : ' Please enter the customer name';
                break;
            case 'RequesterEmail':
                RequesterEmailValid = value.match(/^([a-zA-Z0-9_.-]+)@(([a-zA-Z0-9_-]+)\.){1,2}([a-zA-Z]{2,5})$/i);
                fieldValidationErrors.RequesterEmail = RequesterEmailValid ? '' : ' Please enter the valid email';
                break;
            case 'RequesterName':
                RequesterNameValid = value.length >= 2;
                fieldValidationErrors.RequesterName = RequesterNameValid ? '' : ' Please enter your name';
                break;
            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            RequesterEmailValid: RequesterEmailValid,
            RequesterNameValid: RequesterNameValid,
            MaterialNumberValid: MaterialNumberValid,
            CustomerNameValid: CustomerNameValid,
            SerialNumberValid: SerialNumberValid
        }, this.validateForm);
    }

    validateForm() {
        let formValidity = this.state.MaterialNumberValid && this.state.SerialNumberValid && this.state.CustomerNameValid && this.state.RequesterEmailValid && this.state.RequesterNameValid
        this.setState({ formValid: formValidity });
    }

    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }

    optionChanged(event) {
        let option = {
            optionSelected: event.target.value

        };
        option.SerialNumber = '';
        option.formErrors = this.state.formErrors;
        option.formErrors.SerialNumber = '';
        if (option.optionSelected === 'Serial Number') {
            this.BatchSerial = 'Serial #';
        } else {
            this.BatchSerial = 'Batch #';
        }
        this.setState(option);
    }

    fillData() {
        let fillData = {
            "SerialNumber": "01`3`009A10114",
            "CustomerOrederNumber": "875093",
            "CustomerMaterial": "Metal",
            "MaterialNumber": "P516108",
            "CustomerName": "Suresh",
            //"ShippingDate": "2018-10-10",
            "RequesterName": "Nunmuthu Esakkirajasekar",
            "RequesterEmail": "enunmuthu@external.technip.com",
            "formValid": true,
            "RequesterEmailValid": true,
            "RequesterNameValid": true,
            "MaterialNumberValid": true,
            "CustomerNameValid": true,
            "SerialNumberValid": true
        }
        if (this.state.optionSelected === 'Batch Number') {
            fillData['SerialNumber'] = "A000180397";
            fillData['MaterialNumber'] = "P505313";
        }
        this.setState(fillData, function () {
            this.reqForTest2();
        });

    }

    extractData() {
        let fillData = [
            "SerialNumber",
            "CustomerOrederNumber",
            "CustomerMaterialNumber",
            "SalesOrderNum",
            "lineItem",
            "Quantity",
            "CustomerMaterial",
            "MaterialNumber",
            "CustomerName",
            "ShippingDate",
            "RequesterName",
            "RequesterEmail"
        ];

        let result = {};

        for (var i = 0; i < fillData.length; i++) {
            if (this.state[fillData[i]]) {
                result[fillData[i]] = this.state[fillData[i]];
            }
        }
        if (result.ShippingDate !== undefined && result.ShippingDate !== '') {
            result.ShippingDate = this.formatDate(result.ShippingDate);
        }



        result.IsSerialOrBatch = 'S';
        if (this.state.optionSelected === 'Batch Number') {
            result['BatchNumber'] = result['SerialNumber'];
            result['IsSerialOrBatch'] = 'B';
            delete result['SerialNumber'];
        }

        if (result.CustomerMaterialNumber !== undefined && result.CustomerMaterialNumber !== '') {
            result.CustomerMaterial = result.CustomerMaterialNumber;
            delete result.CustomerMaterialNumber;
        }

        if (result.SalesOrderNum !== undefined && result.SalesOrderNum !== '') {
            result.FMCOrderNumber = result.SalesOrderNum;
            delete result.SalesOrderNum;
        }

        if (result.lineitem !== undefined && result.lineitem !== '') {
            result.LineItem = result.lineitem;
            delete result.lineitem;
        }
        return result;
    }

    reqForTest1(myInput) {
        this.api.getBySerNumber(myInput)
            .then(res => {
                this.setState({ jobNumber: res.data.JobNumber, redirectPage:true });
            })
            .catch(err => {
                console.log(err);
                Toastr.error(err);
                throw (err);
            });
    }

    setLoaderVisibility(visibility){
        this.setState({loading: visibility});
    }

    reqForTest2() {
        let myInput = this.extractData();
        if (this.state.optionSelected === 'Serial Number') {
            this.setLoaderVisibility(true);
            this.api.getBySerNumber(myInput)
                .then(res => {
                    let data = "<a target=\"_blank\" href=\"" + this.api.url + "databook/getStatusHistoryByJobNumber?JobNumber=0fab0656-7c81-42e9-878d-5254918d59d8\">Get Details</a>";
                    document.getElementById('Response').innerHTML = "<p>" + res.data.JobNumber + "</p><p>Click to view the Status</p>" + data;
                    this.setState({ jobNumber: res.data.JobNumber, redirectPage:true, loading: false });
                })
                .catch(err => {
                    this.setLoaderVisibility(false);
                    Toastr.error("Error in generating Databook for Serial Number.");
                    throw (err);
                });
        } else {
            this.setLoaderVisibility(true);
            this.api.getByBatchNumber(myInput)
                .then(res => {
                    let data = "<a target=\"_blank\" href=\"" + this.api.url + "databook/getStatusHistoryByJobNumber?JobNumber=0fab0656-7c81-42e9-878d-5254918d59d8\">Get Details</a>";
                    document.getElementById('Response').innerHTML = "<p>" + res.data.JobNumber + "</p><p>Click to view the Status</p>" + data;
                    this.setState({ jobNumber: res.data.JobNumber, redirectPage:true, loading: false });
                })
                .catch(error => {
                    this.setLoaderVisibility(false);
                    Toastr.error("Error in generating Databook for Batch Number.");
                    throw(error);
                });
        }
    }

    handleChangeDate(date) {
        this.setState({
            startDate: date
        });
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return year + month + day;
    }

    handleSubmit(event) {
        let submitData = {};
        for (var property1 in this.state) {
            if (this.state[property1] && property1 !== 'value') {
                submitData[property1] = this.state[property1];
            }
        }
        if (submitData.ShippingDate !== undefined && submitData.ShippingDate !== '') {
            submitData.ShippingDate = this.formatDate(submitData.ShippingDate);
        }
        this.reqForTest2();
        event.preventDefault();
    }

    render() {
        var redirectPage = this.state.redirectPage;
        let redirectUrl = "status/" + this.state.jobNumber;
        var redirectComp = <LinkRedirect routeName={redirectUrl} />
        return (
            <div className="container">
                {redirectPage && <div> 
                    {redirectComp}
                </div>
                }
                {!redirectPage && <div className="row">
                <Loader loaded={!this.state.loading} />
                    <div className="panel panel-default">
                        <FormErrors formErrors={this.state.formErrors} />
                    </div>
                    {/* <div>
                        <button onClick={this.fillData} className="btn btn-default">Fill Data</button>
                    </div> */}
                    <form onSubmit={this.handleSubmit}>
                        <table id="partNumberTable" className="table-bordered">
                            <tbody>

                                <tr>
                                    <td>Part #<span className="mandatory">*</span></td>
                                    <td colSpan="2">
                                        <input type="text" name="MaterialNumber" id="MaterialNumber" required className={`form-control ${this.errorClass(this.state.formErrors.MaterialNumber)}`} value={this.state.MaterialNumber} onChange={this.handleChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>{this.BatchSerial}<span className="mandatory">*</span></td>
                                    <td colSpan="2">
                                        <label><input type="radio" name="optradio" value="Serial Number" defaultChecked onChange={this.optionChanged} />Serial Number  </label>
                                        <label><input type="radio" name="optradio" value="Batch Number" onChange={this.optionChanged} />Batch Number</label>
                                        <div>
                                            <input type="text" name="SerialNumber" id="SerialNumber" required className={`form-control ${this.errorClass(this.state.formErrors.SerialNumber)}`} value={this.state.SerialNumber} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Customer Name<span className="mandatory">*</span></td>
                                    <td colSpan="2">
                                        <input type="text" name="CustomerName" id="CustomerName" className={`form-control ${this.errorClass(this.state.formErrors.CustomerName)}`} value={this.state.CustomerName} onChange={this.handleChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>PO Number</td>
                                    <td colSpan="2">
                                        <input type="text" name="CustomerOrederNumber" id="CustomerOrederNumber" className="form-control" value={this.state.CustomerOrederNumber} onChange={this.handleChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Customer Part #</td>
                                    <td colSpan="2">
                                        <input type="text" name="CustomerMaterialNumber" id="CustomerMaterialNumber" className="form-control" value={this.state.CustomerMaterialNumber} onChange={this.handleChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>TechnipFMC Sales Order #</td>
                                    <td colSpan="2">
                                        <input type="text" name="SalesOrderNum" id="SalesOrderNum" className="form-control" value={this.state.SalesOrderNum} onChange={this.handleChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Line Item</td>
                                    <td colSpan="2">
                                        <input type="number" name="lineItem" id="lineItem" max="999999" className="form-control" value={this.state.lineItem} onChange={this.handleChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Quantity</td>
                                    <td colSpan="2">
                                        <input type="number" name="Quantity" id="Quantity" className="form-control" value={this.state.Quantity} onChange={this.handleChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Ship Date</td>
                                    <td colSpan="2">
                                        <input type="date" name="ShippingDate" id="ShippingDate" className="form-control" value={this.state.ShippingDate} onChange={this.handleChange} />
                                        {/* <DatePicker style={"width:100%;margin:0px;padding:0px;"} className="form-control" selected={this.state.startDate} onChange={this.handleChangeDate} /> */}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Your Name<span className="mandatory">*</span></td>
                                    <td colSpan="2">
                                        <input type="text" name="RequesterName" id="RequesterName" className={`form-control ${this.errorClass(this.state.formErrors.RequesterName)}`} value={this.state.RequesterName} onChange={this.handleChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Your Email Address<span className="mandatory">*</span></td>
                                    <td colSpan="2">
                                        <input type="text" name="RequesterEmail" id="RequesterEmail" className={`form-control ${this.errorClass(this.state.formErrors.RequesterEmail)}`} value={this.state.RequesterEmail} onChange={this.handleChange} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div id="submitButton">
                            <input type="Submit" value="Submit" className="btn btn-default" disabled={!this.state.formValid} />
                        </div>
                    </form>
                    <div id="Response">

                    </div>
                </div>}
            </div >
        );
    }
}

export default PartNumber;