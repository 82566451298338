import React from 'react';
import apiservice from '../../service/apiService';
import { FormErrors } from '../FormErrors/FormErrors';
import LinkRedirect from '../../component/LinkRedirect.jsx';
import Toastr from 'toastr';
import './multiple.css';
import Loader from 'react-loader';

class Multiple extends React.Component {
    results;
    dispResults;
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: 'SalesOrderNum',
            checkBoxSelected: [],
            totalRows: '0 total rows',
            RequesterName: '',
            RequesterEmail: '',
            CustomerName: '',
            formErrors: {
                RequesterEmail: '',
                RequesterName: '',
                CustomerName: ''
            },
            RequesterEmailValid: false,
            RequesterNameValid: false,
            CustomerNameValid: false,
            showValidFields: 'wholeData showValidFields',
            submitMultipleReq: false,
            getMultipleReqData: false,
            isDataSelected: false,
            multipleRequestId : '',
            redirectPage      : false,
            loading      : false
        };
        this.initialize();
        this.selectedOptionChanged = this.selectedOptionChanged.bind(this);
        this.checkBoxChange = this.checkBoxChange.bind(this);
        this.sendSelected = this.sendSelected.bind(this);
        // this.handleChange = this.handleChange.bind(this);
    }

    initialize() {
        this.api = apiservice.getInstance();
    }

    selectedOptionChanged(event) {
        console.log(event.target.value);
        let stateChangeObj = {};
        stateChangeObj['selectedOption'] = event.target.value;
        this.setState(stateChangeObj);
    }

    checkBoxChange(event) {
        // console.log(event.target.value);
        // console.log(this.state);
        let checkBoxval = event.target.value;
        let checkBoxChecked = event.target.checked;
        let checkBoxModified = [];
        let stateChangeObj = {};
        let check = false;

        if (checkBoxval !== 'selectAll') {
            // if (event.target.checked) {
            if (this.state.results) {
                checkBoxModified = this.state.checkBoxSelected;
                checkBoxModified[checkBoxval] = { value: checkBoxval, checked: checkBoxChecked };
                // for (let i = 0; i < this.state.results.length; i++) {
                //     let checked = {checked: checkBoxChecked};
                //     checkBoxModified.push(checked);
                // }
                check = checkBoxChecked ? checkBoxChecked : this.ischeckBoxSelected();
            }
            // } else {
            //     checkBoxModified = [];
            // }
        } else {
            checkBoxModified = this.state.checkBoxSelected;
            if (event.target.checked) {
                check = true;
            }
            checkBoxModified = this.generateCheckBoxData(this.results.length, check);

        }
        stateChangeObj = { checkBoxSelected: checkBoxModified, isDataSelected: check };

        // console.log(checkBoxModified);
        // State is not executed in synchronous and state is not mutable directly
        // https://stackoverflow.com/questions/30782948/why-calling-react-setstate-method-doesnt-mutate-the-state-immediately
        this.setState(stateChangeObj, function () {
            this.dispResults = this.settableData(this.state.results);
            this.setState({});
        });
        // this.state.checkBoxSelected = checkBoxModified;

    }

    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }

    sendSelected() {
        let checkBoxModified = this.state.checkBoxSelected;
        let serialList = [];
        let batchList = [];
        let selectedList = [];
        let serialNumbersWithMat = [];
        let batchNumbersWithMat = [];
        for (let i = 0; i < checkBoxModified.length; i++) {
            let index = checkBoxModified[i].value;
            let checked = checkBoxModified[i].checked;
            if (checked && this.state.results[index].Sernum !== '') {
                let pushValue = this.state.results[index].Sernum + '_&&_' + this.state.results[index].AssyNum;
                serialList.push(this.state.results[index].Sernum);
                // serialList.push(this.state.results[index]);

                serialNumbersWithMat.push(pushValue);
                let selectedItem = {
                    CustomerOrederNumber: '',
                    FMCOrderNumber: '',
                    CustomerMaterial: '',
                    SerialNumber: '',
                    BatchNumbers: ''
                };

                // selectedItem.CustomerOrederNumber = this.state.results[index].CustPoNum;
                selectedItem.FMCOrderNumber = this.state.results[index].SalesOrderNum;
                selectedItem.SerialNumber = this.state.results[index].Sernum;
                // selectedItem.CustomerMaterial = this.state.results[index].AssyNum;
                selectedList.push(selectedItem);

            } else if (checked && this.state.results[index].BatchNum !== '') {
                let pushValue = this.state.results[index].BatchNum + '_&&_' + this.state.results[index].AssyNum;
                batchList.push(this.state.results[index].BatchNum);
                // batchList.push(this.state.results[index]);

                batchNumbersWithMat.push(pushValue);
                let selectedItem = {
                    CustomerOrederNumber: '',
                    FMCOrderNumber: '',
                    CustomerMaterial: '',
                    SerialNumber: '',
                    BatchNumbers: ''
                };

                selectedItem.CustomerOrederNumber = this.state.results[index].CustPoNum;
                selectedItem.FMCOrderNumber = this.state.results[index].SalesOrderNum;
                selectedItem.BatchNumbers = this.state.results[index].BatchNum;
                // selectedItem.CustomerMaterial = this.state.results[index].AssyNum;
                selectedList.push(selectedItem);

            }
        }
        // console.log(serialList);
        let hardCodeData = {
            "JobNumber": "",
            "CustomerOrederNumber": "",
            "MaterialNumber": "",
            "CustomerMaterial": "",
            "CustomerName": "",
            "Quantity": "",
            "ShippingDate": "",
            "IsSerialOrBatch": "M",
        }
        let sendData = hardCodeData;
        sendData['SerialNumbers'] = serialList;
        sendData['BatchNumbers'] = batchList;
        sendData['selectedList'] = selectedList;
        sendData['SerialNumbersWithMat'] = serialNumbersWithMat;
        sendData['BatchNumbersWithMat'] = batchNumbersWithMat;
        sendData['RequesterName'] = this.state.RequesterName;
        sendData['CustomerName'] = this.state.CustomerName;
        sendData['RequesterEmail'] = this.state.RequesterEmail;

        // console.log(sendData);
        if (!this.state.submitMultipleReq) {
            this.setState({ submitMultipleReq: !this.state.submitMultipleReq })
        }
        this.setState({loading: true});
        this.api.postMultipleSerialBatch(sendData)
            .then(res => {
                // console.log(res);
                // window.location.href = "/mstatus?id=" + res.data[0].MultiplerequestID;
                this.setState({ multipleRequestId: res.data[0].MultiplerequestID, redirectPage:true, loading: false });
            })
            .catch(err => {
                console.log(err);
                this.setState({loading: false});
                Toastr.error("Error in generating Databook.");
                throw (err);
            });
    }



    getSerialList() {
        let fieldValue = document.getElementById('queryVal').value;
        if (!this.state.getMultipleReqData) {
            this.setState({ getMultipleReqData: !this.state.getMultipleReqData })
        }
        this.setState({loading: true});
        this.api.getSerialList(this.state.selectedOption, fieldValue)
            .then(res => {
                // console.log(JSON.parse(res.data));
                let data = JSON.parse(res.data);
                // var tabledata = "<table className=\"table table-striped table-hover\"><tr><th></th><th>AssyDesc</th><th>AssyNum</th><th>BatchNum</th><th> CustPoNum</th><th>DeliveryNum</th><th>QuoteNum</th><th>SalesOrderNum</th><th>Sernum</th></tr>";
                if (data.d.results) {
                    this.results = data.d.results;
                    // console.log(this.results);
                    // for (let i = 0; i < this.results.length; i++) {
                    //     let trData = "<tr><td className=\"checkbox\"><label><input type=\"checkbox\" value=" + i + " checked=\"false\" onChange={this.checkBoxChange}></label></td><td>"
                    //         + this.results[i].AssyDesc + "</td><td>"
                    //         + this.results[i].AssyNum + "</td><td>"
                    //         + this.results[i].BatchNum + "</td><td>"
                    //         + this.results[i].CustPoNum + "</td><td>"
                    //         + this.results[i].DeliveryNum + "</td><td>"
                    //         + this.results[i].QuoteNum + "</td><td>"
                    //         + this.results[i].SalesOrderNum + "</td><td>"
                    //         + this.results[i].Sernum + "</td>"//<td>"
                    //         //+ "<input type=\"button\" value=\"query\" \\> "+ "</td>"
                    //         + "</tr>";
                    //     tabledata = tabledata + trData;

                    // }
                    // tabledata = tabledata + "</table>";
                }
                // document.getElementById('Response').innerHTML = "<p>" + res.data + "</p>"
                // document.getElementById('Response').innerHTML = tabledata;
                let totalRows = this.results.length + ' total rows';
                let checkBoxModified = this.generateCheckBoxData(this.results.length, false);
                this.setState({ getMultipleReqData: true, totalRows: totalRows, checkBoxSelected: checkBoxModified });
                this.dispResults = this.settableData(this.results);

                this.setState({ results: this.results, showValidFields: 'wholeData', totalRows: totalRows, loading: false });
                //}
            })
            .catch(err => {
                console.log(err);
                this.setState({loading: false});
                Toastr.error("Unable to retrive details for the given input. Kindly verify.");
                throw (err);
            });
    }

    generateCheckBoxData(totalRows, check) {
        let totalCheckBox = [];
        for (let i = 0; i < totalRows; i++) {
            let oneCheckBox = { value: i, checked: check };
            totalCheckBox.push(oneCheckBox);
        }
        return totalCheckBox;
    }

    handleChange(event) {
        let stateChangeObj = {};
        if (event.target.name) {
            let stateKey = event.target.name;
            stateChangeObj[stateKey] = event.target.value;
            this.setState(stateChangeObj, () => {
                this.validateField(stateKey, stateChangeObj[stateKey]);
                // console.log(this.state);

            });
        }
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let RequesterEmailValid = this.state.RequesterEmailValid;
        let RequesterNameValid = this.state.RequesterNameValid;
        let CustomerNameValid = this.state.CustomerNameValid;

        switch (fieldName) {
            case 'RequesterEmail':
                // RequesterEmailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                RequesterEmailValid = value.match(/^([a-zA-Z0-9_.-]+)@(([a-zA-Z0-9_-]+)\.){1,2}([a-zA-Z]{2,5})$/i);
                fieldValidationErrors.RequesterEmail = RequesterEmailValid ? '' : ' Please enter the valid email';
                break;
            case 'RequesterName':
                RequesterNameValid = value.length >= 2;
                fieldValidationErrors.RequesterName = RequesterNameValid ? '' : ' Please enter your name';
                break;
            case 'CustomerName':
                CustomerNameValid = value.length >= 2;
                fieldValidationErrors.CustomerName = CustomerNameValid ? '' : ' Please enter the customer name';
                break;
            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            RequesterEmailValid: RequesterEmailValid,
            RequesterNameValid: RequesterNameValid,
            CustomerNameValid: CustomerNameValid
        }, this.validateForm);
    }

    validateForm() {
        let formValidity = this.state.RequesterEmailValid && this.state.RequesterNameValid && this.state.CustomerNameValid;
        this.setState({ formValid: formValidity });
    }

    ischeckBoxSelected() {
        let checkBoxModified = this.state.checkBoxSelected;
        let check = false;
        for (let i = 0; i < checkBoxModified.length; i++) {
            if (checkBoxModified[i] && checkBoxModified[i].checked) {
                check = true;
            }
        }
        // this.setState({ isDataSelected: check });
        return check;
    }

    settableData(tableData) {
        // AssyDesc</th><th></th><th></th></tr>
        let tableDispData = (
            <div>
                <table className="table table-striped">
                    <thead>
                        {/* <tr>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td>
                                <input type="button" value="Send Selected" disabled={!this.state.formValid} onClick={this.sendSelected} className="btn btn-default" />
                            </td>
                        </tr> */}
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th align="right">{this.state.totalRows}</th>
                        </tr>
                        <tr>
                            <th>
                                <label>
                                    <input type="checkbox" value="selectAll" onChange={this.checkBoxChange} />
                                </label>
                            </th>
                            <th>Description</th>
                            <th>Part #</th>
                            <th>Batch #</th>
                            <th>PO #</th>
                            <th>Delivery #</th>
                            <th>Quote #</th>
                            <th>TechnipFMC Sales Order #</th>
                            <th>Serial Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.getTableBody(tableData)}
                        {/* <tr>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td>
                                <input type="button" value="Send Selected" disabled={!this.state.formValid} onClick={this.sendSelected} className="btn btn-default" />
                            </td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
        )
        return tableDispData;
    }

    getTableBody(getTableBod) {
        // console.log(getTableBod);
        return getTableBod.map((row, index) =>
            <tr key={index}>
                <td>
                    <label>
                        <input type="checkbox" value={index} checked={this.state.checkBoxSelected[index].checked} onChange={this.checkBoxChange} />
                    </label>
                </td>
                <td>
                    {row.AssyDesc}
                </td>
                <td>
                    {row.AssyNum}
                </td>
                <td>
                    {row.BatchNum}
                </td>
                <td>
                    {row.CustPoNum}
                </td>
                <td>
                    {row.DeliveryNum}
                </td>
                <td>
                    {row.QuoteNum}
                </td>
                <td>
                    {row.SalesOrderNum}
                </td>
                <td>
                    {row.Sernum}
                </td>
            </tr>
        );
    }
    // {/* <tr>
    //     <td>Your Name<span className="mandatory">*</span></td>
    //     <td colSpan="2">
    //         <input type="text" name="RequesterName" id="RequesterName" className={`form-control ${this.errorClass(this.state.formErrors.RequesterName)}`} value={this.state.RequesterName} onChange={this.handleChange} />
    //     </td>
    // </tr>
    // <tr>
    //     <td>Your Email Address<span className="mandatory">*</span></td>
    //     <td colSpan="2">
    //         <input type="text" name="RequesterEmail" id="RequesterEmail" className={`form-control ${this.errorClass(this.state.formErrors.RequesterEmail)}`} value={this.state.RequesterEmail} onChange={this.handleChange} />
    //     </td>
    // </tr> */}

    render() {
        var redirectPage = this.state.redirectPage;
        let redirectUrl = "mstatus/" + this.state.multipleRequestId;
        var redirectComp = <LinkRedirect routeName={redirectUrl} />
        return (
            <div className="container">
                {redirectPage && <div>
                    {redirectComp}
                </div>
                }
                {!redirectPage && <div>
                <Loader loaded={!this.state.loading} />
                <div id="multipleQuery">
                    <div className="row">
                        <div className="col-md-10">
                            <h5>Please Select an option and click submit</h5>
                            <label><input type="radio" name="queryOptradio" value="SalesOrderNum" defaultChecked onChange={this.selectedOptionChanged} />TechnipFMC Sales Order #</label>
                            <label><input type="radio" name="queryOptradio" value="CustPoNum" onChange={this.selectedOptionChanged} />PO # </label>
                            <label><input type="radio" name="queryOptradio" value="DeliveryNumber" onChange={this.selectedOptionChanged} />Delivery #  </label>
                            {/* 
                        {this.state.selectedOption} */}
                        </div>
                    </div>
                    <div className="row">
                        <div class="col-md-6">
                            <input type="text" autoComplete="false" className="form-control" id="queryVal" ref="someName" />
                            {/* <input type="button" value="x" /> */}
                        </div>
                    </div>

                    {/* <div>
                    <h3>Query By</h3>
                    <label><input type="radio" name="queryOptradio" value="SalesOrderNum" defaultChecked onChange={this.selectedOptionChanged} />TechnipFMC Order Number</label>
                    <label><input type="radio" name="queryOptradio" value="CustPoNum" onChange={this.selectedOptionChanged} />Purchase Order Number </label>
                    <label><input type="radio" name="queryOptradio" value="DeliveryNumber" onChange={this.selectedOptionChanged} />Delivery Number </label>
                </div> */}

                    <div>
                        {/* <input type="text" id="queryVal" /> */}
                        <input type="button" value="Submit" className="btn btn-default" onClick={() => this.getSerialList()} />
                    </div>
                    {/* {this.state.getMultipleReqData ? <div><p>please wait while we are submitting your request ...</p></div> : null} */}
                </div>
                {/* <div className="removeCon">
                    <h2>Will be removed in prod :</h2>
                    <p>TechnipFMC Order Number = 875093</p>
                    <p>Delivery Number = 82242585</p>
                    <p>Purchase Order Number = 4513778546</p>
                </div> */}
                <div className={this.state.showValidFields}>

                    <div className="panel panel-default">
                        <FormErrors formErrors={this.state.formErrors} />
                    </div>
                    <form>
                        <table class="multipleTable">
                            <tbody>
                                <tr>
                                    <td>Your Name<span className="mandatory">*</span></td>
                                    <td colSpan="2">
                                        <input type="text" name="RequesterName" id="RequesterName" className={`form-control ${this.errorClass(this.state.formErrors.RequesterName)}`} value={this.state.RequesterName} onChange={this.handleChange.bind(this)} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Your Email Address<span className="mandatory">*</span></td>
                                    <td colSpan="2">
                                        <input type="text" name="RequesterEmail" id="RequesterEmail" className={`form-control ${this.errorClass(this.state.formErrors.RequesterEmail)}`} value={this.state.RequesterEmail} onChange={this.handleChange.bind(this)} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Customer Name<span className="mandatory">*</span></td>
                                    <td colSpan="2">
                                        <input type="text" name="CustomerName" id="CustomerName" className={`form-control ${this.errorClass(this.state.formErrors.CustomerName)}`} value={this.state.CustomerName} onChange={this.handleChange.bind(this)} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                    <p>Please Select the needed documents from the checkbox and click send selected</p>
                    <div className="rightButton">
                        <input type="button" value="Send Selected" disabled={!this.state.formValid || !this.state.isDataSelected} onClick={this.sendSelected} className="btn btn-default " />
                    </div>
                    {this.state.submitMultipleReq ? <div className="rightButton"><p>please wait while we are submitting your request ...</p></div> : null}
                    <div id="Response">
                        {this.dispResults}
                    </div>
                    {this.state.submitMultipleReq ? <div className="rightButton"><p>please wait while we are submitting your request ...</p></div> : null}
                </div>
                </div>}
            </div>
        );
    }

}

export default Multiple;