import React, { Component } from 'react';
import './App.css';
import './scss/App.scss';
import './/../node_modules/toastr/build/toastr.css';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import DataBooksHeader from './component/DataBooksHeader/dataBooksHeader';
import DataBookTab from './component/DataBookTab/dataBookTab';
import Status from './component/status/status';
import MultipleStatus from './component/MultipleStatus/MultipleStatus';
import DownloadFile from './component/DownloadFile';
import Toastr from 'toastr';


Toastr.options ={
  positionClass : 'toast-bottom-right'
}

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <div>
            <DataBooksHeader></DataBooksHeader>
            <Switch>
              <Route path="/" exact component={DataBookTab} />}
              <Route path="/status/:jobNumber" component={Status} />
              <Route path="/mstatus/:multipleRequestId" component={MultipleStatus} />
              <Route path="/downloadfile/:jobNumber" component={DownloadFile} />
            </Switch>
          </div>
        </div >
      </Router>
    );
  }
}
export default App;




