import React from 'react';
import './dataBookTab.css';
import PartNumber from '../Part Number/partNumber';
import Multiple from '../Multiple/multiple';
import Status from '../status/status';

class DataBookTab extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            loadTab: 'S',
            jobNumber: ''
        }

        this.goToStatusPage = this.goToStatusPage.bind(this)
    }

    handleTabChange1(tabValue) {
        this.setState({ loadTab: tabValue });
    }

    goToStatusPage(jobNum) {
        this.setState({ loadTab: 'Status', jobNumber: jobNum });
    }

    setTabClass(classSwitch) {
        if (this.state.loadTab === classSwitch) {
            return 'purpleBackground';
        }

    }


    render() {
        let tabContent;
        if (this.state.loadTab === 'S') {
            tabContent = <PartNumber onClick={this.goToStatusPage}></PartNumber>;
        } else if (this.state.loadTab === 'Status') {
            tabContent = <Status statusFromServer={this.state.jobNumber}></Status>;
        } else {
            tabContent = <Multiple></Multiple>
        }

        return (

            <div className="container">
                <div className="navtab">
                    <p className={`${this.setTabClass('S')}`} onClick={() => this.handleTabChange1('S')}>Part Number</p>
                    <p className={`${this.setTabClass('M')}`} onClick={() => this.handleTabChange1('M')}>Multiple</p>
                </div>
                {tabContent}
            </div>
        );
    }
}

export default DataBookTab;