import React from 'react';
import './dataBooksHeader.css';

class DataBooksHeader extends React.Component {
    render() {
        return (
            <div className="container databook-header">
                <div>

                    <div className="row">
                        <div>
                            <img src="./technipfmc-logo.png" className="headerLogo" alt="TechnipFMC" />
                        </div>
                    </div>
                    <h3 id="title">Electronic Databook</h3>
                    {/* <div className="row">
                        <div className="col-md-12">
                            <img className="img-fluid w-100" src="./FC-Data Books.jpg" alt="Not Dispalyed" />
                        </div>
                    </div> */}
                    {/* <p>This application on this page allows you to print electronic databook and or component material test
                        reports for individual assets or for multiple assets. All you need to know is the material/part number
                    and serial number for assemblies or material/part number and traceability number for components.</p>
                    <p>Assembly information material/part number and serial number is located on product name plate or band.</p>
                    <p>Example: </p>
                    <ul>
                        <li>3255323 (assembly part number)</li>
                        <li>070809A10066 (serial number)</li>
                    </ul>
                    <p>Component information is hard stamped on the actual material with its location varying depending on the
                    product.</p>
                    <p>Example 1:</p>
                    <ul>
                        <li>3207277 (part number)</li>
                        <li>PN2 (traceability number)</li>
                    </ul>
                    <p>Example 2:</p>
                    <ul>
                        <li>P500792 (part number)</li>
                        <li>A2287 (traceability number)</li>
                    </ul>
                    <p>Please note:</p>
                    <ul>
                        <li>With this format (A###) you need to enter in a 10 digit numeric format A000002287</li>
                        <li>When submitting request for individual components no serial number is required</li>
                        <li>When submitting request for serialized assemblies no other traceability information is required</li>
                    </ul>
                    <br />
                    <h3>Application Instruction:</h3>
                    <p>Please fill out the information below following the above guidelines to start this process. If you have
                    any question or issues please feel free to contact TechnipFMC Technologies.</p>
                    <p>Thank you for using our electronic documentation service.</p> */}
                    <p>Electronic Databook are available by a combination of Part Number/Serial Number, Part Number/Batch Numbers, TechnipFMC Sales Order Number, Purchase Order Number, or Delivery Number.  When the processing is complete, an email will be sent with a link to download the Electronic Databook.</p>
                </div>
            </div>
        );
    }
}

export default DataBooksHeader;