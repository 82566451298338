import React from 'react';
import moment from 'moment';

class FileNameRow extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
          fileName: props ? props.FileName : '',
          createdDate : props ? moment(props.CreatedDate).utc().format('MMMM DD YYYY h:mm A z') : '',
          expiryDate : props ? moment(props.CreatedDate).utc().add(10,'days').format('MMMM DD YYYY h:mm A z') : '',
          selected: props  ? props.Selected : false
        };
        this.handleCheck         = this.handleCheck.bind(this);
    }
    handleCheck(event) {
        const isSelectChecked = event.target.checked;
        this.setState({selected: isSelectChecked});
        this.props.selectFile(this.state.fileName, isSelectChecked);
    } 

    componentDidUpdate(prevProps) {
        if (this.props.Selected !== prevProps.Selected) {
            this.setState({selected: this.props.Selected});
        }
    }

    render(){
      var state = this.state;
        return (
            <tr>
                <td className='select-check-box'><input type="checkbox" onChange={this.handleCheck} checked={this.state.selected} /></td>
                <td>{state.fileName}</td>
                <td>{state.createdDate}</td>
                <td>{state.expiryDate}</td>
            </tr>
        );
    }
}

export default FileNameRow